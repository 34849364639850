import React, { useEffect, useState } from "react";
import Logo from "../Logo/Logo";
import HomeNavBar from "../HomeNavbar/HomeNavbar";
import HeroContent from "../HeroContent/HeroContent";
import Container from "../../Container/Container";
import { useLocation } from "react-router";

interface IHeroSection {
  institutionName: string;
}

const HeroSection: React.FC<IHeroSection> = ({ institutionName }) => {
  const location = useLocation();

  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          "/papi/super-admin/settings/home-page-image"
        );

        if (response.ok) {
          const blob = await response.blob();
          const imageObjectUrl = URL.createObjectURL(blob);
          setImageUrl(imageObjectUrl);
        } else {
          console.error("Failed to fetch image: ", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, []);

  return (
    <Container>
      <div className="relative flex flex-col min-h-screen hero-section">
        {location.pathname === "/home" || location.pathname === "/" ? (
          <header className="flex justify-between items-center md:p-4 md:static fixed w-full z-50">
            <div className="hidden md:block">
              <Logo />
            </div>
            <HomeNavBar />
          </header>
        ) : null}
        <div className="flex flex-col md:flex-row items-center md:justify-between px-6 mt-[200px] md:mt-[100px]">
          <div className="md:w-1/2 w-full flex justify-center">
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="Home Page Image"
                className="max-w-full max-h-[600px] md:max-h-[400px] object-contain"
              />
            ) : (
              <p>Loading ...</p>
            )}
          </div>
          <div className="w-full md:w-1/2">
            <HeroContent institutionName={institutionName} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HeroSection;
