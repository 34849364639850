import React from "react";
import { IonGrid, IonRow, IonCol, IonText } from "@ionic/react";

const Footer: React.FC = () => {
  return (
    <footer className="py-6">
      <IonGrid>
        <IonRow className="flex flex-col items-center justify-between md:flex-row">
          <IonCol className="flex justify-center md:justify-start">
            <IonText className="flex flex-col items-center justify-center md:flex-row">
              <a
                href="/"
                className="mx-2 my-4 text-center text-gray-600 md:my-0"
              >
                Home
              </a>
              <a
                href="https://www.zerra.in/"
                className="mx-2 my-4 text-center text-gray-600 md:my-0"
              >
                Company
              </a>
            </IonText>
          </IonCol>
          <IonCol className="flex justify-center mt-2 md:justify-end md:mt-0">
            <IonText className="my-4 md:my-0">
              <p className="py-4 my-4 text-center text-gray-600 md:my-0 md:py-0">
                Copyright © 2019{" "}
                <a href="https://www.zerra.in/" className="text-[#2ca8ff]">
                  Zest Softech Pvt. Ltd.
                </a>
              </p>
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </footer>
  );
};

export default Footer;
